import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App'; // App component is also inside the src folder

ReactDOM.render(
  <BrowserRouter> {/* Wrap the app in BrowserRouter */}
    <App />
  </BrowserRouter>,
  document.getElementById('root') // This matches the 'root' div in public/index.html
);
