import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import './App.css';
import n11Logo from './icons/N11.png'; // İkonun yolu
import trendyolLogo from './icons/Trendyol.png'; // İkonun yolu
import hepsiburadaLogo from './icons/Hepsiburada.png'; // İkonun yolu
import ciceksepetiLogo from './icons/Ciceksepeti.png'; // İkonun yolu
import pazaramaLogo from './icons/pazarama.png'; // İkonun yolu
import ozonLogo from './icons/Ozon.png'; // İkonun yolu
import pttLogo from './icons/Ptt.png'; // İkonun yolu
import amazonLogo from './icons/Amazon.png'; // İkonun yolu
import { useNavigate } from 'react-router-dom';
import { calculateApi } from './api';
Chart.register(...registerables);


function App() {
    const navigate = useNavigate();
    const [expenses, setExpenses] = useState([]);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [formattedCalculation, setFormattedCalculation] = useState('');
    const [profit, setProfit] = useState(0); // Net profit state
    const [marketPlace, setMarketPlace] = useState(0); // Net profit state
// Market yerleri 
const MarketPlaces = {
    N11: { label: 'N11', icon: n11Logo,id:1}, // N11 ikonu
    Trendyol: { label: 'Trendyol', icon:trendyolLogo,id:2}, // Trendyol ikonu
    CicekSepeti: { label: 'ÇiçekSepeti', icon: ciceksepetiLogo ,id:3}, // ÇiçekSepeti ikonu
    Hepsiburada: { label: 'Hepsiburada', icon: hepsiburadaLogo,id:4 }, // Hepsiburada ikonu
    Ptt: { label: 'Ptt', icon: pttLogo ,id:5}, // Ptt ikonu
    Pazarama: { label: 'Pazarama', icon: pazaramaLogo,id:6 }, // Pazarama ikonu
    Ozon: { label: 'Ozon', icon: ozonLogo ,id:7}, // Ozon ikonu
    Amazon: { label: 'Amazon', icon: amazonLogo,id:8 }, // Amazon ikonu
};

const handleMarketChange = (market) => {
    setSelectedMarket(market.label);
    setMarketPlace(market.id);
    const url=`/${market.label}-komisyon-hesaplama-araci`
    navigate(url); // Update the URL
  };
const [selectedMarket, setSelectedMarket] = useState(); 
    const resultChartRef = useRef(null);
    const vatChartRef = useRef(null);
    //hesaplama sonucu veriler
    const [totalProfit, setTotalProfit] = useState(0);
    const [chart, setChart] = useState(null);
    const [vatChart, setVatChart] = useState(null);
    const [responseData, setResponseData] = useState();
    
    // girilen veriler
    const [purchase, setPurchase] = useState();
    const [sale, setSale] = useState();
    const [commissionRate, setCommissionRate] = useState();
    const [vatRate, setVatRate] = useState();
    const [shipping, setShipping] = useState();

    // açıklamanın atandığı değişken
    const [calculationDetails, setCalculationDetails] = useState("");

    const calculate = async () => {
        const commissionRateDecimal = commissionRate / 100;
        const vatRateDecimal = vatRate / 100;

        if (isNaN(purchase) || isNaN(sale) || isNaN(commissionRateDecimal) || isNaN(vatRateDecimal) || isNaN(shipping)) {
            alert("Lütfen tüm alanları doldurun.");
            return;
        }
        if (!selectedMarket) {
            alert("Lütfen Pazaryeri Seçin.");
            return;
        }
    

        const updatedFormData = {
            marketPlace: marketPlace, 
            purchase: purchase || 0,
            sale: sale || 0,
            commissionRate: commissionRate || 0,
            vatRate: vatRate || 0,
            shipping: shipping || 0
        };

       
       //apiye istek atılıyor
try {
    const response = await calculateApi(updatedFormData);
    //dönen veri set ediliyor 
     setResponseData(response);
   
} catch (error) {
    console.error('hata:', error);
}
if(responseData==null){
return;
}



        //apiden gelen veri değişkenlere atanıyor
        const saleVat = responseData?.kdv.saleKdv;
        const purchaseVat = responseData?.kdv.purchaseKdv;
        const shippingWithoutVat = shipping / (1 + vatRateDecimal);
        const shippingVat = responseData?.kdv.shippingKdv;

        const commission = responseData?.commision;
        const commissionVat = responseData?.kdv.commissionKdv;
        const serviceFeeVat = responseData?.kdv.serviceFeeKdv;

        const serviceFee = responseData?.serviceFee;

        const totalVat = saleVat - (purchaseVat + shippingVat + commissionVat + serviceFeeVat);
        const profit = responseData?.profit;

        setTotalProfit(profit.toFixed(2));

        //kullanılan formul açıklaması
        setCalculationDetails(`Kârınızı hesaplamak için kullanılan formül:
        Kâr = Satış Fiyatı - (Alış Fiyatı + Komisyon + Kargo + KDV + Hizmet Bedeli)
        
        Sonuç: ${profit.toFixed(2)} TL
        = ${sale.toFixed(2)} - (${purchase.toFixed(2)} + ${commission.toFixed(2)} + ${shipping.toFixed(2)} + ${totalVat.toFixed(2)} + ${serviceFee.toFixed(2)})`);


     
        const expensesData = [
            { label: 'Alış Fiyatı', value: purchase.toFixed(2) },
            { label: 'Komisyon', value: commission.toFixed(2) },
            { label: 'Kargo', value: shipping.toFixed(2) },
            { label: 'KDV', value: totalVat.toFixed(2) },
            { label: 'Hizmet Bedeli', value: serviceFee.toFixed(2) },
        ];

        // Giderler ve toplam gideri hesapla
        const total = expensesData.reduce((sum, item) => sum + parseFloat(item.value), 0);

        // State'leri güncelle
        setExpenses(expensesData);
        setTotalExpenses(total);
        setFormattedCalculation(`
            Kâr = Satış Fiyatı - (Alış Fiyatı + Komisyon + Kargo + KDV + Hizmet Bedeli)
            
            Sonuç: ${profit.toFixed(2)} TL
            = ${sale.toFixed(2)} - (${purchase.toFixed(2)} + ${commission.toFixed(2)} + ${shipping.toFixed(2)} + ${totalVat.toFixed(2)} + ${serviceFee.toFixed(2)})
        `);
        const profitValue = sale - (totalVat + commission + shipping + serviceFee + purchase);

   // Update state with the calculated values
   setProfit(profitValue);
   setTotalProfit(profitValue.toFixed(2));
      



        updateChart(commission, totalVat, shippingWithoutVat, profit, purchase, serviceFee);
        updateVatChart(saleVat, purchaseVat, shippingVat, commissionVat, serviceFeeVat);
    };
//normal grafik
    const updateChart = (commission, totalVat, shippingWithoutVat, profit, purchase, serviceFee) => {
        const ctx = resultChartRef.current.getContext('2d');
        const data = {
            labels: ['Alış Fiyatı', 'Komisyon', 'Toplam KDV', 'Kargo', 'Hizmet Kesintisi', 'Kâr'],
            datasets: [{
                label: 'TL cinsinden',
                data: [purchase, commission, totalVat, shippingWithoutVat, serviceFee, profit],
                backgroundColor: ['#3498db', '#e74c3c', '#f39c12', '#f39c12', '#f39c12', '#2ecc71'],
                borderWidth: 1
            }]
        };

        if (chart) {
            chart.destroy(); // Önceki grafiği yok et
        }
        const newChart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                scales: {
                    y: { beginAtZero: true }
                },
                plugins: {
                    legend: { display: true },
                    title: { display: true, text: 'TL Cinsinden Değerler' }
                }
            }
        });
        setChart(newChart); // Yeni grafiği state'e kaydet
    };
//pasta grafiği
    const updateVatChart = (saleVat, purchaseVat, shippingVat, commissionVat, serviceFeeVat) => {
        const ctx = vatChartRef.current.getContext('2d');
        const data = {
            labels: ['Satış KDV', 'Alış KDV', 'Kargo KDV', 'Komisyon KDV', 'Hizmet Bedeli KDV'],
            datasets: [{
                label: 'TL cinsinden',
                data: [saleVat, purchaseVat, shippingVat, commissionVat, serviceFeeVat],
                backgroundColor: ['#3498db', '#f39c12', '#e74c3c', '#2ecc71', '#8e44ad'],
                borderWidth: 1
            }]
        };

        if (vatChart) {
            vatChart.destroy(); // Önceki grafiği yok et
        }
        const newVatChart = new Chart(ctx, {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                plugins: {
                    legend: { position: 'top' },
                    title: { display: true, text: 'KDV Dağılımı' }
                }
            }
        });
        setVatChart(newVatChart); // Yeni grafiği state'e kaydet
    };


    useEffect(() => {
        updateChart(0, 0, 0, 0, 0, 0);
        updateVatChart(0, 0, 0, 0, 0);
        // Sayfa yüklendiğinde URL'yi güncelle
        if (!selectedMarket) {
            navigate('/komisyon-hesaplama-araci'); // Seçili pazar yeri yoksa varsayılan URL
        }
    }, [selectedMarket, navigate]);

    
    return (
        
        <div className="App">
               <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"></link>
               <h1 className="text-2xl font-bold m-4">
    {selectedMarket ? `${selectedMarket} - Komisyon Hesaplama Aracı` : 'Komisyon Hesaplama Aracı'}
</h1>

             <div className="market-selector">
    {Object.values(MarketPlaces).map((market) => (
        <button 
            key={market.label} 
            className={`market-option ${selectedMarket === market.label ? 'selected' : ''}`}
            onClick={() => handleMarketChange(market)}
        >
            <img src={market.icon} alt={market.label} className="market-icon" />
        </button>
    ))}
</div>
            <div className="container ">
            <div className="chart-container">
                    <canvas ref={vatChartRef} id="vatChart"></canvas>
                </div>
                <form id="calculator-form" className='calculator-form'>
                    <div className="input-with-icon" data-icon="🛒">
                        <input type="number" placeholder="Alış Tutarını Girin" value={purchase} onChange={(e) => setPurchase(parseFloat(e.target.value))} required />
                    </div>
                    <div className="input-with-icon" data-icon="💵">
                        <input type="number" placeholder="Satış Tutarını Girin" value={sale} onChange={(e) => setSale(parseFloat(e.target.value))} required />
                    </div>
                    <div className="input-with-icon" data-icon="💼">
                        <input type="number" placeholder="Komisyon Oranı (%)" value={commissionRate} onChange={(e) => setCommissionRate(parseFloat(e.target.value))} required />
                    </div>
                    <div className="input-with-icon" data-icon="🧾">
                        <input type="number" placeholder="KDV Oranı (%)" value={vatRate} onChange={(e) => setVatRate(parseFloat(e.target.value))} required />
                    </div>
                    <div className="input-with-icon" data-icon="📦">
                        <input className="calculate-input" type="number" placeholder="Kargo Tutarı" value={shipping} onChange={(e) => setShipping(parseFloat(e.target.value))} required />
                    </div>
                    <button type="button" className="calculate-button" onClick={calculate}>Hesapla</button>
                </form>
                <div className="chart-container">
                    <canvas ref={resultChartRef} id="resultChart"></canvas>
                </div>
            </div>
            <div className="total-profit">Toplam Kar: {totalProfit} TL</div>
         
          
{calculationDetails &&

    <div className="max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-5 mb-5">
       
    <div className="bg-blue-600 text-white p-4">
        <h2 className="text-xl font-bold">Kâr Hesaplama Detayı</h2>
    </div>
    <div className="p-4">
        <div className="space-y-4">
            {/* Display Sales Price */}
            <div className="flex justify-between items-center">
                <span className="text-base font-semibold">Satış Fiyatı</span>
                <span className="text-base font-bold text-green-600">{sale.toFixed(2)} TL</span>
            </div>

            <div className="border-t border-gray-200 pt-2">
                <h3 className="text-base font-semibold mb-2">Giderler:</h3>
                {expenses.map((expense, index) => (
                    <div key={index} className="flex justify-between items-center mb-1">
                        <span className="text-gray-600">{expense.label}</span>
                        <span className="font-medium">{expense.value} TL</span>
                    </div>
                ))}
            </div>

            {/* Display Total Expenses */}
            <div className="flex justify-between items-center border-t border-gray-200 pt-2">
                <span className="text-base font-semibold">Toplam Giderler</span>
                <span className="text-base font-bold text-red-600">{totalExpenses?.toFixed(2)} TL</span>
            </div>

            {/* Display Net Profit */}
            <div className="flex justify-between items-center border-t border-gray-200 pt-2">
                <span className="text-lg font-bold">Net Kâr</span>
                <span className="text-lg font-bold text-green-600">{profit.toFixed(2)} TL</span>
            </div>

            {/* Display Calculation Details */}
            <div className="mt-4 bg-gray-100 p-2 rounded-lg">
                <h3 className="text-base font-semibold mb-1">Hesaplama Detayları:</h3>
                <pre className="text-sm whitespace-pre-wrap font-mono">{formattedCalculation}</pre>
            </div>
        </div>
    </div>
</div>


}


        </div>
    );
}

export default App;
